import React from "react";

function article_1() {
	return {
		date: "1 June 2024",
		title: "Importance of workplace culture and DEIB",
		description:
			"I'm not proud to say that I didn't know what DEIB was before I joined Auror. Fast forward to now, I just can't imagine working for a place that underestimates the importance of a healthy culture and care for DEIB. The only downside is that my standards have become way too high.",
		keywords: [
			"importance of work culture",
			"work culture",
			"healthy workplace",
			"DEI",
			"DEIB",
			"Yuney",
			"Yuney Chonlee",
			"Auror",
		],
		style: `
				.article-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid red;
				}
				`,
		body: (
			<React.Fragment>
				<div className="article-content">
					<div className="paragraph">Content of article 1</div>
					<img
						src="https://picsum.photos/200/300"
						alt="random"
						className="randImage"
					/>
				</div>
			</React.Fragment>
		),
	};
}

function article_2() {
	return {
		date: "7 March 2024",
		title: "Why I transitioned into a software QA engineer role",
		description:
			"I started as a grad at a world leading healthcare R&D company. I stayed 7 years, and even went on to get a Master's in medical devices and technology. But it wasn't long I decided to leave.",
		style: ``,
		keywords: [
			"switch path qa",
			"career transition qa",
			"qa interview",
			"break qa job",
			"early career advice",
			"Yuney",
			"Yuney Chonlee",
		],
		body: (
			<React.Fragment>
				<h1>Content of article 2</h1>
			</React.Fragment>
		),
	};
}

const myArticles = [article_1, article_2];

export default myArticles;
