import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/works.css";

const Works = () => {
	return (
		<div className="works">
			<Card
				icon={faBriefcase}
				title="Work"
				body={
					<div className="works-body">
						<a href="https://www.auror.co/" target="_blank" rel="noreferrer">
							<div className="work">
								<img
									src="https://pbs.twimg.com/profile_images/1548592037187858432/zz6378tm_400x400.jpg"
									alt="Auror logo"
									className="work-image"
								/>
								<div className="work-title">Auror</div>
								<div className="work-subtitle">
									Senior QA Engineer
								</div>
								<div className="work-duration">2022 - Present</div>
							</div>
						</a>

						<a href="https://www.fphcare.com/" target="_blank" rel="noreferrer">
							<div className="work">
									<img
										src="https://pbs.twimg.com/profile_images/1411885798547329034/vKmlkjBD_400x400.jpg"
										alt="FPH logo"
										className="work-image"
									/>
								<div className="work-title">Fisher & Paykel Healthcare</div>
								<div className="work-subtitle">
									Product Development Engineer
								</div>
								<div className="work-duration">2015 - 2022</div>
							</div>
						</a>
					</div>
				}
			/>
		</div>
	);
};

export default Works;
