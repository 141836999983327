const INFO = {
	main: {
		title: "Yuney's Quality Space",
		name: "Yuney S. Chonlee",
		email: "y.chonlee@gmail.com",
		logo: "../logo.jpg",
	},

	socials: {
		xtwitter: "https://x.com/yuney1002",
		github: "https://github.com/yuneychonlee",
		linkedin: "https://www.linkedin.com/in/yuney-chonlee/",
	},

	homepage: {
		title: "Curious and adventurous",
		description:
			"Detail-oriented engineer with a passion for elevating software quality standards and practices. Experienced in facilitating the adoption of shift-left testing methodologies to enhance development processes. Skilled in leveraging modern QA tools and environments to optimise testing efficiency and effectiveness. Committed to fostering a culture of continuous improvement and the whole team owning quality.",
	},

	about: {
		title: "About Me",
		description:
			"I started my engineering career in a world leading healthcare company, Fisher & Paykel Healthcare, that specialises in medical device research and development.\
			Testing was an important part of the product development lifecycle, and the experiences had grown my passion for embedding quality into the engineering processes. \
			I then transitioned my career to solely focus in software QA and testing. I joined the Auror team of retail crime fighters in a fast paced SaaS industry, and it was one of the best decisions in my life. \
			 My strengths are being curious, coachable and adaptable. I believe these qualities have been essential in my professional growth thus far and will continue to serve me well in any new challenge. \
                    I am confident in my ability to swiftly adopt new information and processes, \
                    allowing me to contribute effectively to the success of the team and the overall business objectives. \
			I am enthusiastic about new opportunities to deliver high quality software to the end users.",
	},

	articles: {
		title: "Recollections and Reflections",
		description:
			"“Sharing knowledge is not about giving people something, or getting something from them. That is only valid for information sharing. Sharing knowledge occurs when people are genuinely interested in helping one another develop new capacities for action; it is about creating learning processes.” - Peter Senge",
		// backups:
		// 	"If you have knowledge, let others light their candles in it. - Margaret Fuller",
		// 	"Disseminating knowledge is the human duty, sharing it about so that all can benefit. – Tracy Rees",
		// "Everyone has transferable commodity knowledge. Sharing your unique expertise and making introductions for someone creates a lasting legacy. – Marsha Blackburn"
	},

	projects: [
		{
			title: "Cypress test automation",
			description:
				"Demo of cypress automation for e2e UI flows and API tests in Typescript.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos@0.0.3/src/typescript/typescript.png",
			linkText: "View Project",
			link: "https://github.com/YuneyC/cypress-web-automation",
		},

		{
			title: "Random password generator",
			description:
				"A simple password generator using HTML/CSS/JS.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/css/css.png",
			linkText: "View Project",
			link: "https://github.com/yuneychonlee/password-gen/",
		},

		{
			title: "Travel journal",
			description:
				"A simple travel journal created with React (JS). A log of my recent places visited.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com/yuneychonlee/travel-journal",
		},

		{
			title: "Tactile force sensing system",
			description:
				"A masters research project to build a force sensing system.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos@0.0.3/src/python/python.png",
			linkText: "View Project",
			link: "https://github.com/yuneychonlee/masters-project/tree/main",
		},

		{
			title: "A prosthetic gripper",
			description:
				"An adaptive, non-anthropomorphic prosthetic gripper has been designed with regards to usability in everyday life.",
			logo: "https://banner2.cleanpng.com/20180425/kqw/kisspng-computer-icons-solidworks-e-5ae0fa43334702.1540505415246935712101.jpg",
			linkText: "View Project",
			link: "https://github.com/yuneychonlee/prosthetic-gripper/tree/main",
		},
	],
};

export default INFO;
